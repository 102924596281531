.card {

}

.style-undefined {
  background-color:white!important;
  color:black!important;
}
.style-PLAN {
  background: white!important;
  color:black!important;
}
.style-PARTIAL {
  background: cornflowerblue!important;
  color:black!important;
}
.style-PARTIAL-LATE {
  background: orange!important;
  color:black!important;
}
.style-PLAN-LATE {
  background: #FF3333!important;
  color:white!important;
}
.style-DONE {
  background: green!important;
  color:black!important;
}
.style-PREPARED {
  background: lightyellow!important;
  color:black!important;
}
.style-SENT {
  background: gray!important;
  color:black!important;
}
.blackIcon {
  color:black!important;
}
.redIcon {
  color:#AA0000!important;
}

.redButton {
  background-color:#FF3333;
  border-color: red;
}

.modal-dialog {
  max-width: none;
}














.root {
  overflow-y: scroll;
}

.error {
  color: red;
  font-weight: bold;
}


.App {
  box-sizing: border-box;
  margin: 0;
  background: white;
  height: 100vh;
  overflow-y: scroll;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.app-bar-title {
  flex-grow: 1;
}

.main-container {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
}

.todo-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.auth-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
